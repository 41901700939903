import { useContext, useEffect } from 'react'
import { AppContext, DialogueContext, PageContext, Privilages } from '../App';
import Network from '../utilities/Network';

const useContent = () => {
    const dialogueContext = useContext(DialogueContext)
    const pageContext = useContext(PageContext)
    const privilageContext = useContext(Privilages)
    const appContext = useContext(AppContext)

    const isLoggedIn = () => localStorage.getItem("admin") != null

    const loadAdminPrivilages = () => {
        new Network({
            url: '/getAdminPrivilages.php',
            method: 'post',
            context: pageContext,
            params: {
                adminId: JSON.parse(localStorage.getItem("admin")).id
            },
            dialogueContext: dialogueContext,
            deliable: false,
            onConnectionSucceed: (data) => {
                appContext.value.fromMap(data.app)
                privilageContext.setPrivilages(data.adminsPrivilages, data.admin)
            }
        }).invoke()
    }

    useEffect(() => {
        if (isLoggedIn() === true) loadAdminPrivilages()
        // eslint-disable-next-line
    }, [isLoggedIn()])

    return {
        isLoggedIn: isLoggedIn()
    }
}
export default useContent