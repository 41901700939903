import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { RTLContext } from '../App'

function Dialogue({ visible = false, tag = "default", title = "تنبيه", content, children, cont }) {
    const context = useContext(cont)
    const rtlContext = useContext(RTLContext)
    return <Modal show={visible} centered onHide={() => context.hideDialogue(tag)} style={{
        direction: rtlContext ? "rtl" : "ltr"
    }}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>{content}</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={e => context.hideDialogue(tag)}>إلغاء</Button>
            {children}
        </Modal.Footer>
    </Modal>
}

export default Dialogue
