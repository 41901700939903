import useContent from './useContent';
import { Route, Switch } from "react-router-dom";
import { lazy } from 'react'

const AddQuestions = lazy(() => import('../AddQuestions/addQuestions'))
const Reports = lazy(() => import("../Reports/Reports"))
const BrowseTerms = lazy(() => import("../BrowseTerms/browseTerms"))
const Admins = lazy(() => import('../Admins/Admins'))
const User = lazy(() => import('../user/User'))
const CouponsTypes = lazy(() => import('../Coupons/CouponsTypes'))
const CouponsType = lazy(() => import('../Coupons/CouponsType'))
const CouponSearch = lazy(() => import('../Coupons/Search'))
const Coupons = lazy(() => import("../Coupons/Coupons"))
const Admin = lazy(() => import("../Admin/Admin"))
const AddAdmin = lazy(() => import("../Admins/AddAdmin/AddAdmin"))
const QuestionsTags = lazy(() => import("../QuestionsTags/QuestionsTags"))
const QuestionsTag = lazy(() => import("../QuestionsTags/QuestionsTag/QuestionsTag"))
const AddQuestionsChildTag = lazy(() => import("../QuestionsTags/AddChildTag/AddChildTag"))
const Notifications = lazy(() => import("../Notifications/Notifications"))
const EditQuestionTag = lazy(() => import("../QuestionsTags/EditQuestionTag/EditQuestionTag"))
const SearchQuestionsTags = lazy(() => import("../QuestionsTags/SearchQuestionsTags/SearchQuestionsTags"))
const TermQuestionsTags = lazy(() => import("../QuestionsTags/TermQuestionsTags/TermQuestionsTags"))
const Dashboard = lazy(() => import("../Dashboard/Dashboard"))
const Flashcards = lazy(() => import("../Flashcards/Flashcards"))
const AddFlashcard = lazy(() => import("../AddFlashcard/AddFlashcard"))
const EditFlashcard = lazy(() => import("../Flashcards/EditFlashcard/EditFlashcard"))
const YoutubeVideo = lazy(() => import("../YoutubeVideo/YoutubeVideo"))
const Ebook = lazy(() => import("../Ebook/Ebook"))
const Chat = lazy(() => import("../Chat/Chat"))
const Term = lazy(() => import('../BrowseTerms/term'))
const SimilarQuestions = lazy(() => import("../Questions/Similar/SimilarQuestions"))
const QuestionsSearch = lazy(() => import('../Questions/search/Search'))
const SearchQuestionsByTag = lazy(() => import("../Questions/QuestionsTags/QuestionsTags"))
const TelegramQuestions = lazy(() => import("../TelegramQuestions/TelegramQuestions"))
const AddTelegramQuestion = lazy(() => import("../AddTelegramQuestion/AddTelegramQuestion"))
const TelegramQuestion = lazy(() => import("../TelegramQuestion/TelegramQuestion"))
const Voice = lazy(() => import("../Voice/Voice"))
const Users = lazy(() => import("../Users/Users"))
const DelayedRequests = lazy(() => import("../DelayedRequests/DelayedRequests"))
const Images = lazy(() => import('../Images/Images'))
const AddImage = lazy(() => import("../AddImage/AddImage"))
const UserCoupons = lazy(() => import("../Coupons/UserCoupons"))
const RelatedTerms = lazy(() => import("../RelatedTerms/RelatedTerms"))
const TermPrints = lazy(() => import("../BrowseTerms/TermPrints/TermPrints"))

function Content() {

    const { isLoggedIn } = useContent();
    const listingString = '/(filter)?/:filter?/(page)?/:page?/(numberOfItems)?/:numberOfItems?'
    return <div>
        {
            isLoggedIn ? <Switch>
                <Route path={"/addQuestions/termId/:termId"} component={AddQuestions} />
                <Route path={"/translateQuestions/termId/:termId"} component={AddQuestions} />
                <Route path="/admins" exact component={Admins} />
                <Route path="/user/:id" component={User} />
                <Route path={`/reports${listingString}`} component={Reports} />
                <Route path="/couponsTypes/(termCycle)?/:termCycle?" exact component={CouponsTypes} />
                <Route path="/couponsType/:couponType/today" exact component={Coupons} />
                <Route path="/couponsType/:couponType/activations/(termCycle)?/:termCycle?" exact component={Coupons} />
                <Route path="/couponsType/:couponType/term/:termId/today" exact component={Coupons} />
                <Route path={`/term/:termId/prints${listingString}`} exact component={TermPrints} />
                <Route path="/couponsType/:couponType/term/:termId/activations/(termCycle)?/:termCycle?" exact component={Coupons} />
                <Route path="/couponsType/:couponType/(termCycle)?/:termCycle?" exact component={CouponsType} />
                <Route path="/coupons/(termCycle)?/:termCycle?" exact component={Coupons} />
                <Route path="/admin/:id" component={Admin} />
                <Route path="/coupons/search/:query/(skip)?/:skip?" component={CouponSearch} />
                <Route path="/admins/add" component={AddAdmin} />
                <Route path="/questionsTags" exact component={QuestionsTags} />
                <Route path="/questionsTags/:tagId" exact component={QuestionsTag} />
                <Route path="/questionsTags/:tagId/edit" exact component={EditQuestionTag} />
                <Route path="/questionsTags/search/:query" component={SearchQuestionsTags} />
                <Route path="/questionsTags/termId/:termId" component={TermQuestionsTags} />
                {/* <Privilage privilageId="10" > */}
                <Route path="/questionsTags/:tagId/add" component={AddQuestionsChildTag} />
                {/* </Privilage> */}
                {/* <Privilage privilageId="5" > */}
                <Route path={`/notifications${listingString}`} component={Notifications} />
                {/* </Privilage> */}
                <Route path="/browseTerms/rootTermId/:rootTermId/(termId)?/:termId?" component={BrowseTerms} />
                <Route path="/" exact component={Dashboard} />
                <Route path="/flashcards/:termId" exact component={Flashcards} />
                <Route path="/flashcards/:termId/add" component={AddFlashcard} />
                <Route path="/images/:termId" exact component={Images} />
                <Route path="/images/:termId/add" exact component={AddImage} />
                <Route path="/flashcard/:flashcardId/edit" component={EditFlashcard} />
                <Route path="/youtubeVideo/:parentTermId" component={YoutubeVideo} />
                <Route path="/ebook/:parentTermId" component={Ebook} />
                <Route path="/chat/:itemType/:itemId" component={Chat} />

                {/* getUserConfirmation={(message, callback) => {
                    // this is the default behavior
                    const allowTransition = window.confirm(message);
                    callback(allowTransition);
                }} */}

                <Route path="/questions/search/:query/(skip)?/:skip?" component={QuestionsSearch} />
                <Route path={["/question/:questionId/similar/(skip)?/:skip?"]} component={SimilarQuestions} />
                <Route path="/questions/tag/:tagId/(skip)?/:skip?" component={SearchQuestionsByTag} />
                <Route path="/termId/:termId" component={Term} />
                <Route path="/telegramQuestions" exact component={TelegramQuestions} />
                <Route path="/telegramQuestions/add" component={AddTelegramQuestion} />
                <Route path="/telegramQuestion/:questionId" component={TelegramQuestion} />
                <Route path="/voice/:parentTermId" component={Voice} />
                <Route path="/users/search/:query" component={Users} />
                <Route path="/delayedRequests" component={DelayedRequests} />
                <Route path="/coupons/user/:userId" component={UserCoupons} />
                <Route path="/term/:termId/related" component={RelatedTerms} />
            </Switch >
                : <></>
        }
    </div >
}
export default Content